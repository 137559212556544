@import '../../variables';

.map-wrapper {
  height: 100vh;
  width: 100%;
}

.marker--distance {
  background: $blue;
  border-radius: 5px;
  color: $white;
  font-size: 1.5rem; // 4k => 1.5rem; hd => 1.2rem
  font-weight: bold;
  padding: 1px 4px;
  text-align: center;

  @media screen and (max-width: 2500px) {
    font-size: 1.2rem;
  }

  &__active {
    background: $pink;
  }
}

.icon--africa {
  width: 3rem;

  @media screen and (max-width: 2500px) {
    width: 2rem;
  }

  svg {
    max-width: 100%;
  }
}

.btn {
  bottom: 4vw;
  position: absolute;
  right: 0;
}
