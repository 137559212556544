@import '../../variables';

h1 {
  color: $text-color-light;
  font-size: 6vw;
  font-weight: $fw-black;
  line-height: 1.2;
}

.title-wrapper,
.subtitle-wrapper {
  left: 4rem;
  overflow: hidden;
  position: absolute;
  top: 4rem;
  width: 100%;
}

.subtitle-wrapper {
  top: 9vw;
}

.title {
  opacity: 0;
  text-shadow: transparentize($black, .8) 0 0 20px;
  transform: translateY(-100%);
  transition: opacity .5s ease-out, transform .5s cubic-bezier(.51, .77, .77, 1);

  &.active {
    opacity: 1;
    transform: translateY(0);
  }

  &.animate-out {
    animation: slideIn .5s cubic-bezier(.88, .18, .33, 1.01);
  }
}

.subtitle {
  color: $text-color-light;
  font-size: 2vw;
  font-weight: $fw-light;
  opacity: 0;
  text-shadow: transparentize($black, .7) 0 0 10px;
  transform: translateY(-100%);
  transition: opacity .5s .2s ease-out, transform .5s .2s cubic-bezier(.51, .77, .77, 1);

  @media screen and (max-width: 1900px) {
    top: 16rem;
  }

  &.active {
    opacity: 1;
    transform: translateY(0);
  }

  &.animate-out {
    animation: slideIn .5s .2s cubic-bezier(.88, .18, .33, 1.01);
  }
}

.bg-image {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  opacity: 0;
  position: absolute;
  transition: opacity .5s linear;
  width: 100%;
  z-index: -1;

  &.active {
    opacity: 1;
  }
}

.front {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  z-index: 1;
}

.front--buttons {
  display: flex;
  width: 100%;
}

@keyframes slideIn {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}
