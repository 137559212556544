.loader {
  height: 100vh;
  position: relative;
  transition: opacity .2s ease-out;
  width: 100%;

  &.hide {
    opacity: 0;
  }

  img {
    bottom: 0;
    left: 0;
    margin: auto;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity .2s ease-out;
    width: 30vw;

    &.show {
      opacity: .8;
    }
  }
}

.error {
  align-items: center;
  color: #686868;
  display: flex;
  font-size: calc(1.6rem + .3vw);
  height: 100vh;
  justify-content: center;
  opacity: 0;
  transition: opacity .2s .2s ease-out;

  &.show {
    opacity: 1;
  }

  h2 {
    margin-bottom: 0;
  }

  .divider {
    background: rgba(0, 0, 0, .4);
    height: 2.5vw;
    margin: 0 calc(2rem + .5vw);
    min-height: 3rem;
    width: 1px;
  }
}
