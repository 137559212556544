html {
  font-family: 'roboto', sans-serif;
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow: hidden;
}

strong {
  font-weight: 600;
}

h2 {
  font-weight: 600;
}
