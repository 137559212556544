$text-color-light: #fff;
$text-color-dark: #363636;

$pink: #f20072;
$blue: #1d9ad4;
$green: #0db986;
$yellow: #cf8a05;
$white: #fff;
$black: #000;
$lightgrey: #dfdfdf;

$nl: $pink;
$fr: $blue;
$en: $green;
$de: $yellow;

$main-font: 'roboto', sans-serif;

$fw-light: 300;
$fw-normal: 400;
$fw-bold: 500;
$fw-black: 900;
