.card-wrapper {
  display: flex;
  height: 100vh;
  justify-content: space-between;
  min-height: 100%;
  padding: 2vw 2vw 5vw;
  perspective: 5000px;
}

.slide-enter {
  opacity: .01;
  transform: translateY(-10rem);

  &.slide-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 3s ease-out, transform 3s ease-out;
  }
}

.slide-leave {
  opacity: 1;
  transform: translateY(0);

  &.slide-leave-active {
    opacity: .01;
    transform: translateY(-10rem);
    transition: opacity 3s ease-out, transform 3s ease-out;
  }
}
