@import '../../variables';

.sidebar {
  background-color: $white;
  box-shadow: 0 0 10px transparentize($black, .8);
  color: $text-color-dark;
  display: flex;
  flex-direction: column;
  font-size: .7vw;
  height: 100vh;
  justify-content: space-between;
  opacity: 0;
  overflow-y: auto;
  padding-bottom: 3.3vw;
  position: absolute;
  right: -25%;
  top: 0;
  transition: opacity .25s cubic-bezier(.72, .3, .61, .94), right .25s cubic-bezier(.72, .3, .61, .94);
  width: 25%;
  will-change: right, opacity;

  @media screen and (max-width: 2500px) {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 1800px) {
    font-size: 1.5rem;
  }

  &.show {
    opacity: 1;
    right: 0;

    .sidebar--bottom,
    .sidebar--top {
      opacity: 1;
    }
  }

  &::-webkit-scrollbar {
    width: .7rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $blue;
  }
}

.sidebar--bottom {
  align-items: center;
  border-top: 1px solid transparentize($black, .9);
  display: flex;
  justify-content: space-between;
  opacity: 0;
  padding: 1.2vw 2vw;
}

.sidebar--top {
  opacity: 0;
}

.sidebar--image {
  max-height: 30%;
  object-fit: cover;
  width: 100%;
}

.sidebar--content {
  padding: 1.2vw 2vw;

  @media screen and (max-width: 1900px) {
    padding: 2rem 5rem;
  }
}

.sidebar--title {
  font-size: 1.2vw;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 2rem;
  text-shadow: none;

  @media screen and (max-width: 1900px) {
    font-size: 2.8rem;
  }
}

.sidebar--text {
  color: $text-color-dark;
  font-weight: $fw-light;
  line-height: 1.5;

  @media screen and (max-width: 1900px) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 1800px) {
    font-size: 1.5rem;
  }
}

.tips {
  margin-top: 4rem;
}

.list {
  list-style: none;
}

.list-item {
  margin-left: 1vw;
  position: relative;

  &::before {
    background: url('../../assets/img/checked.svg');
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: .6vw;
    left: -1vw;
    margin: auto;
    position: absolute;
    top: .6rem;
    width: .6vw;
  }

  @media screen and (max-width: 2500px) {
    margin-left: 2.5rem;

    &::before {
      height: 1.2rem;
      left: -2rem;
      width: 1.2rem;
    }
  }
}

.background--title {
  background-color: $blue;
  border-radius: .5rem;
  color: $white;
  display: inline-block;
  font-size: .7vw;
  font-weight: 700;
  margin-bottom: 1rem;
  padding: .2rem .5rem;
  text-transform: uppercase;

  @media screen and (max-width: 2500px) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 1800px) {
    font-size: 1.4rem;
  }
}

.website--url {
  color: $pink;
  font-weight: $fw-bold;
  max-width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-with-icon {
  margin-top: 4rem;

  svg {
    display: inline-block;
    height: 2rem;
    margin-right: 1rem;
    vertical-align: middle;
    width: auto;
  }
}

.text-with-icon__distance {
  svg {
    height: 3rem;
  }
}

.text-with-icon--details {
  display: inline-block;
  font-weight: $fw-bold;
  max-width: calc(100% - 3rem);
  vertical-align: top;
}
