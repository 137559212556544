@import '../../variables';

.nav {
  display: flex;
  margin-left: 8vw;
}

.nav--link {
  align-items: center;
  border-left: 1px solid transparentize($black, .9);
  display: flex;
  font-size: 1vw;
  font-weight: $fw-light;
  height: 100%;
  letter-spacing: 2px;
  position: relative;
  text-transform: uppercase;

  &.has-border-right {
    border-right: 1px solid transparentize($black, .9);
  }

  @media screen and (max-width: 1900px) {
    font-size: 1.6rem;
  }

  a {
    color: $text-color-dark;
    padding: 0 1vw;
    text-decoration: none;

    @media screen and (max-width: 1900px) {
      padding: 0 1.5rem;
    }
  }

  svg {
    min-width: 2rem;
    width: 1.3vw;
  }

  span {
    padding: 0 1vw;
  }
}
