@import '../../variables';

.image-placeholder {
  background: $lightgrey;
  height: 25vh;
  position: relative;
  width: 100%;

  @media screen and (max-width: 1600px) {
    height: 20vh;
  }

  .placeholder {
    bottom: 0;
    height: 5rem;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
  }
}
