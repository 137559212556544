@import '../../variables';

.cta--button {
  background-color: $blue;
  border-radius: 10px;
  bottom: 4vw;
  box-shadow: 0 0 35px transparentize($black, .8);
  color: $white;
  font-weight: $fw-bold;
  outline: 0;
  padding: 1vw 1.5vw;
  position: absolute;
  right: 1%;
  transition: right .25s .25s ease-out;

  @media screen and (max-width: 2500px) {
    padding: 2rem 3rem;
  }

  &.move-to-right {
    right: 26%;
  }

  &.disabled {
    display: none;
  }
}

.cta--icon,
.cta--text {
  display: inline-block;
  vertical-align: middle;
}

.cta--icon {
  margin-right: .5vw;
  width: 1vw;
  @media screen and (max-width: 2500px) {
    margin-right: 1.5rem;
    width: 3rem;
  }

  &__big {
    width: 1.5vw;
  }
}

.cta--text {
  font-size: 1vw;
  font-weight: $fw-bold;

  @media screen and (max-width: 2500px) {
    font-size: 2rem;
  }
}
