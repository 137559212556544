@import '../../variables';

.card {
  background-size: cover;
  border-radius: 1rem;
  box-shadow: 0 0 45px transparentize($black, .6);
  flex: 1;
  margin: 1vw;
  opacity: 0;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transform: translateY(-10rem) translateZ(0);
  will-change: opacity, transform;

  &.show {
    animation: cardSlide .5s ease-out forwards;

    &:nth-child(2) {
      animation: cardSlide .5s .2s ease-out forwards;
    }

    &:nth-child(3) {
      animation: cardSlide .5s .4s ease-out forwards;
    }

    &:nth-child(4) {
      animation: cardSlide .5s .6s ease-out forwards;
    }
  }

  &__disabled {
    &.show {
      &::before {
        background: transparentize($white, .3);
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
}

.card__small {
  .card--title-wrapper {
    padding: 10rem 7rem;
    @media screen and (max-width: 2500px) {
      padding: 5rem 4rem;
    }
  }

  .card--title {
    font-size: 2.8vw;
  }

  .card--subtitle {
    font-size: 1.3vw;
  }
}

.card--top-image {
  position: absolute;
  right: 0;
  top: 0;
  width: 70%;
}

.card--title-wrapper {
  background: linear-gradient(to bottom, transparentize($black, .4), transparent);
  padding: 15rem 5rem 8rem;

  @media screen and (max-width: 2500px) {
    padding-top: 8rem;
  }
}

.card--title {
  font-size: 5.1vw;
  hyphens: auto;
  line-height: 1;
  margin-bottom: 0;
  max-width: 100%;
  position: relative;
  text-shadow: none;

  @media screen and (max-width: 2500px) {
    font-size: 4.6vw;
  }
}

.card--subtitle {
  color: $white;
  font-weight: $fw-light;
}

@keyframes cardSlide {
  0% {
    opacity: 0;
    transform: translateY(-10rem) translateZ(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
}
