.website--qr {
  position: relative;

  svg {
    bottom: 1rem;
    position: absolute;
    right: 110%;
    width: 6rem;

    @media screen and (max-width: 1800px) {
      width: 4rem;
    }
  }
}
