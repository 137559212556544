@import '../../variables';

.footer {
  // animation: slideUp .5s ease-out forwards;
  bottom: 0;
  left: 0;
  position: fixed;
  width: 100%;
}

.back-button {
  // animation: scale .25s .5s ease-out forwards;
  background-color: $blue;
  border-radius: 50%;
  border-style: inherit;
  bottom: -2vw;
  box-shadow: 0 0 20px transparentize($black, .56);
  height: 7vw;
  left: -1.4vw;
  margin: auto;
  min-height: 10rem;
  min-width: 10rem;
  outline: none;
  position: absolute;
  text-align: left;
  // transform: scale(0);
  // transform-origin: 0 100%;
  width: 7vw;
}

.back-button--icon {
  display: flex;
  height: 80%;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  transform: rotateY(180deg);
  width: 90%;

  svg {
    min-width: 2rem;
    width: 1.6vw;
  }
}

.footer--bar {
  background: $white;
  border-top: 1px solid transparentize($black, .9);
  display: flex;
  justify-content: space-between;
}

.footer--right,
.language-switcher {
  display: flex;
}

.footer--right {
  align-items: center;
  justify-content: flex-end;
}

.uitinvlaanderen--image {
  margin-right: 5rem;
  max-width: 8vw;

  @media screen and (max-width: 1800px) {
    margin-right: 3rem;
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes slideUp {
  0% {
    bottom: -10rem;
    opacity: 0;
  }

  100% {
    bottom: 0;
    opacity: 1;
  }
}
