@import '../../variables';

.react-select__group-heading {
  font-weight: bold;
}

// CSS Modules
.react-select__menu {
  animation: fade--in .2s ease-in-out;
}

.react-select__indicators {
  position: relative;
  width: 2.5vw;

  @media screen and (max-width: 2500px) {
    width: 3rem;
  }

  @media screen and (max-width: 2100px) {
    width: 2rem;
  }
}

.dropdown-indicator {
  border-bottom: .1vw solid $blue;
  border-right: .1vw solid $blue;
  bottom: 0;
  display: inline-block;
  height: .5vw;
  left: 0;
  margin: auto;
  min-height: 1rem;
  min-width: 1rem;
  position: absolute;
  right: 0;
  top: -.5rem;
  transform: rotateX(0deg) rotateZ(45deg);
  transform-origin: 50%;
  transition: transform .3s ease-out, top .2s ease-out;
  vertical-align: middle;
  width: .5vw;

  @media screen and (max-width: 1900px) {
    border-bottom-width: 1px;
    border-right-width: 1px;
  }

  &.active {
    top: .5rem;
    transform: rotateX(180deg) rotateZ(45deg);
  }
}

@keyframes fade--in {

  0% {
    opacity: 0;
    transform: translateY(5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
