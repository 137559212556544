@import '../../variables';

.event {
  box-shadow: -10px 0 25px -10px transparentize($black, .8);
  color: $text-color-dark;
  display: flex;
  flex: 1 0 20%;
  flex-direction: column;
  font-size: .7vw;
  height: 100vh;
  justify-content: space-between;
  line-height: 1.5;

  @media screen and (max-width: 2500px) {
    font-size: 1.6rem;
  }

  @media screen and (max-width: 1800px) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 1600px) {
    font-size: 1.2rem;
  }

  svg {
    display: inline-block;
    height: 2.5rem;
    margin-right: 1.5rem;
    vertical-align: middle;
    width: auto;

    @media screen and (max-width: 2500px) {
      height: 2rem;
      margin-right: 1rem;
    }

    @media screen and (max-width: 1600px) {
      height: 1.5rem;
      margin-right: .5rem;
    }
  }

  h2 {
    @media screen and (max-width: 2500px) {
      font-size: 2.8rem;
    }

    @media screen and (max-width: 1800px) {
      font-size: 2.4rem;
    }

    @media screen and (max-width: 1600px) {
      font-size: calc(2rem);
    }
  }

  .website--url {
    max-width: 70%;

    @media screen and (max-width: 1600px) {
      font-size: 1rem;
    }
  }
}

.event--image-wrapper {
  border-bottom: .5vw solid $pink;
  position: relative;

  &::after {
    background: $white;
    border: .5vw solid $pink;
    border-radius: 50%;
    bottom: -1.3vw;
    content: '';
    height: 1vw;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 1vw;
  }

  span {
    display: inline !important;
  }
}

.event--image {
  height: 25vh;
  object-fit: cover;
  width: 100%;

  @media screen and (max-width: 1600px) {
    height: 20vh;
  }
}

.event--top {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.event--content {
  padding: 1.2vw 1.5vw 1vw;
}

h2 {
  color: $text-color-dark;
  font-size: calc(18px + 1vw);
  line-height: 1.2;
  margin-bottom: 1rem;
}

.inline {
  display: inline-block;
}

.event--dates {
  font-size: .6vw;
  font-weight: 500;
  padding: 1rem 0;

  @media screen and (max-width: 2500px) {
    font-size: 1.4rem;
  }

  @media screen and (max-width: 1800px) {
    font-size: 1.2rem;
  }

  @media screen and (max-width: 1600px) {
    font-size: 1.1rem;
  }
}

.align__middle {
  display: inline-block;
  vertical-align: middle;
}

.event--text {
  font-weight: $fw-light;
  margin-bottom: 3rem;
  word-break: break-word;

  @media screen and (max-width: 1600px) {
    margin-bottom: 1rem;
  }
}

.event--location {
  svg {
    height: 2.5rem;
    margin-top: .5rem;
    vertical-align: top;

    @media screen and (max-width: 2500px) {
      height: 2rem;
    }
  }
}

.location--details {
  max-width: calc(100% - 3.5rem);
}

.location--title {
  display: block;
}

.event--price {
  svg {
    vertical-align: middle;
  }
}

.event--price,
.event--age,
.event--website {
  margin-top: 2rem;

  @media screen and (max-width: 1600px) {
    margin-top: 1rem;
  }
}

.event--bottom {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: .5vw 2vw 4vw;

  @media screen and (max-width: 1600px) {
    padding-bottom: 4.5vw;
  }
}

.slick-slide {
  outline: none;
}
