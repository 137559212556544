@import '../../variables';

.fade-enter {
  opacity: .01;

  &.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
}

.fade-exit {
  opacity: 1;

  &.fade-exit-active {
    opacity: .01;
    transition: opacity 300ms ease-in;
  }
}

.page {
  background: $white;
  height: 100vh;
  position: fixed;
  top: 0;
  transition: transform .5s cubic-bezier(.25, .05, .45, .95);
  width: 100%;
  will-change: transform;
}

.page-enter {
  // transform: translateY(100%);
  transform: translateX(100%);
  z-index: -1;

  .footer {
    opacity: 0;
  }
}

.page-prev-enter {
  transform: translateX(-100%);
  z-index: -1;

  .footer {
    opacity: 0;
  }
}

.page-enter-active,
.page-prev-enter-active {
  // transform: translateY(0);
  transform: translateX(0);
  z-index: 1;

  .footer {
    opacity: 1;
  }
}

.page-exit,
.page-prev-exit {
  // transform: translateY(0);
  transform: translateX(0);
  z-index: -1;

  .footer {
    opacity: 0;
  }
}
