@import '../../variables';

.slick-list {
  padding-left: 0 !important;
}

.swipe-icon {
  background: transparentize($black, .8);
  border-radius: 50%;
  bottom: 10vw;
  height: 3vw;
  left: 0;
  margin: auto;
  opacity: 0;
  position: fixed;
  right: 0;
  transform: scale(0);
  transition: opacity .25s ease-out, transform .25s cubic-bezier(.77, .15, .22, 1.47);
  width: 3vw;

  &.show {
    opacity: 1;
    transform: scale(1);
  }

  svg {
    bottom: 0;
    height: auto;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
  }
}
