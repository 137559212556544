@import '../../variables';

.locale-button {
  background-color: $white;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 2.7vw 0;
  text-align: center;
  transition: background-color .25s ease-out;
  width: 100%;

  &__small {
    border-right: 1px solid transparentize($black, .9);
    padding: 1vw;

    @media screen and (max-width: 1900px) {
      padding: 2rem;
    }
  }

  &__disabled {
    cursor: default;
    opacity: .4;
  }
}

.button--text {
  color: $text-color-light;
  font-size: 2vw;
  font-weight: $fw-bold;
}

.button--text__small {
  font-size: 1vw;
}

.button--text__chevron {
  &::after {
    border-right: .1vw solid $text-color-light;
    border-top: .1vw solid $text-color-light;
    content: '';
    display: inline-block;
    height: 1vw;
    margin-left: 2rem;
    transform: rotate(45deg);
    vertical-align: middle;
    width: 1vw;
  }
}
