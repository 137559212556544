@import '../../variables';

.date {
  background: $blue;
  border-radius: 5px;
  color: $white;
  margin-bottom: 1rem;
  margin-right: 2rem;
  padding: .2rem .5rem;

  @media screen and (max-width: 2500px) {
    margin-right: 1.5rem;
  }

  @media screen and (max-width: 1600px) {
    margin-right: .5rem;
  }
}

.time {
  font-weight: bold;
}

.opening_hours {
  margin-bottom: 1rem;
}
